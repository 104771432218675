import React from 'react';
// import './products.css';
import { useHistory } from "react-router-dom";

function ProductGridItem({id, image, name, price }) {

    const history = useHistory();

    const handleSelectedProduct = () => {
        history.push(`/products/lhydro/product/${id}`);
    }

    return (
        <div className="cardboard-product">
            <div className="image-product-component">
                <img src={image} alt={name} />
                <button  onClick={handleSelectedProduct}  className="btn-product"><i className="far fa-eye"></i></button>
            </div>
            <p className="name-product">{name}</p>
            <p className="price-product">${price}</p>
            <button className="btn btn-primary button-buy">Comprar</button>
        </div>
    )
}

export default ProductGridItem;