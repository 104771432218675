import React from 'react'

export const KnowledgeCenter = () => {
    return (
        <>
            <div className="centro-de-conocimiento">
                <h2>
                    Centro de conocimiento
                </h2>
                <p>
                    lorem ipsum dolor sit amet, con
                </p>
                <button className="btn btn-light">Blog</button>
            </div>
        </>
    )
}
