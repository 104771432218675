import React, { useEffect, useState } from 'react';
import carouselRes from '../../helpers/resourceMapper';
// import './home.css';

export const CarouselElem = () => {

    const [currentImg, setCurrentImg] = useState(0);
    const [showImg, setShowImg] = useState(true);

    const carouselResource = carouselRes;

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setInterval(() => {
                if (!unmounted) {
                    setShowImg(false);
                    setShowImg(true);
                }
                if (currentImg === carouselResource.carouselRes.length - 1) {
                    if (!unmounted) {
                        setCurrentImg(0);
                    }
                } else {
                    if (!unmounted) {
                        setCurrentImg(currentImg + 1);
                    }
                }
            }, 8000);
        }
        return () => {
            unmounted = true;
        }

    }, [currentImg, setShowImg, carouselResource.carouselRes.length]);


    return (
        <>
            <div className="carousel__container-general">
                {
                    showImg &&
                    <img src={process.env.PUBLIC_URL + carouselResource.carouselRes[currentImg]} alt="car" />
                }
            </div>

        </>
    )
}
