import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';
import { useDispatch } from 'react-redux';
import { loginWithCreds } from '../../actions/auth';
// import './authentication.css';

export const Login = () => {

    const dispatch = useDispatch();
    const [loginAvaiable, setLoginAvaiable] = useState(true);

    const [formVals, handleInputChange] = useForm({
        email: '',
        password: ''
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoginAvaiable(false);
        setTimeout(() => {
            setLoginAvaiable(true);
        }, 800);
        // console.log(formVals);
        dispatch(loginWithCreds(formVals));
    }

    return (
        <>
            <div className="login-container">
                <div className="login-titulo">
                    <h1>Iniciar Sesión</h1>
                </div>
                <p className="login-parafo">Hola, si ya tienes una cuenta en DesignDLR ingresa con tus datos, sino <Link to="/register" className="login-link-register">regístrate aquí</Link></p>
                <div className="login-form-container">
                    <form className="login-form-components" onSubmit={handleSubmit}>
                        <label className="nombre-form-title" >Correo</label>
                        <div>
                            <i className="fas fa-at"></i>
                            <input type="email" className="input-form" autoComplete="new-off" name="email" onChange={handleInputChange} />
                        </div>
                        <label className="nombre-form-title" >Contraseña</label>
                        <div>
                            <i className="fas fa-lock"></i>
                            <input type="password" className="input-form" autoComplete="new-off" name="password" onChange={handleInputChange} />
                        </div>
                        <Link to="/recover" >Olvidé mi contraseña</Link>
                        <button disabled={!loginAvaiable} className="btn btn-primary">Iniciar Sesión</button>
                    </form>
                </div>
            </div>
        </>
    )
}
