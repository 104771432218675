import React from 'react';
import { CarouselElem } from '../home/CarouselElem';
// import './about.css';

export const About = () => {
    return (
        <>
            <div className="carousel-content">
                <CarouselElem />
            </div>
            <div className="about__container-general">
                <div className="content-about">
                    <h1>Sobre nosotros</h1>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Totam fugiat, necessitatibus labore pariatur qui accusantium commodi ea praesentium ratione nobis laboriosam, animi ad eos placeat nam excepturi at doloribus dolorum.
                    </p>
                    <i className="fas fa-seedling"></i>
                    <h1>Meta</h1>
                    <p>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Praesentium quo necessitatibus quaerat? Magnam nihil ipsum quasi dicta error eum dolor, harum officia laborum voluptates laboriosam in ex repudiandae tempora totam. Lorem ipsum
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Soluta, pariatur dignissimos impedit officia, architecto odio obcaecati voluptates aliquam necessitatibus corrupti dolores. Saepe reprehenderit ea aperiam asperiores quae laboriosam nihil sapiente?
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Est suscipit nesciunt consequuntur dolor soluta quas quo, ullam vel eos odit quia tempore voluptatibus vitae, beatae expedita eius assumenda asperiores blanditiis!
                    </p>
                    <i className="fas fa-seedling"></i>
                    <h1>A futuro</h1>
                    <p>
                        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aliquam vero adipisci, beatae eius quidem architecto fugit omnis laboriosam ipsa. Qui sint voluptate corrupti, cupiditate totam explicabo excepturi iure! Consequatur, quos.
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt iusto aliquid, reiciendis veritatis voluptatibus vitae dolorum molestias rerum itaque quae ex error nisi sed dicta soluta tempore labore adipisci consequatur!
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Natus vero esse cumque vel excepturi molestias amet officiis officia animi, ad ipsa totam quis assumenda. Atque quaerat aperiam consequatur magnam quae!
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit blanditiis quam, repellat ad magni, asperiores labore quo temporibus hic, nostrum dolorum. Incidunt expedita eos asperiores quaerat officiis dignissimos a? Ratione.
                    </p>
                </div>
            </div>
        </>
    )
}
