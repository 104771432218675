import http from '../axios/http-common';

const getAll = () => {
    return http.get('product/list');
}

const getById = (id) => {
    return http.get(`product/id/${id}`);
}

//Busca producto por nombre exacto (Poco util pero dejarlo)
const getByName = (name) => {
    return http.get(`product/name/${name}`);
}

//Busca listado de productos que tengan un nombre parecido
const getByNameLike = (name) => {
    return http.get(`product/namelike/${name}`);
}

const exportedObject = {
    getAll,
    getById,
    getByName,
    getByNameLike,
};

export default exportedObject;