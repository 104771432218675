import http from '../axios/http-common';

const getMessageByOwner = (email) => {
    return http.get(`log/owner/${email}`);
}

const exportedObject = {
    getMessageByOwner,
};

export default exportedObject;