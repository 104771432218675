import { useEffect, useState } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { About } from '../components/about/About';
import { Contact } from '../components/contact/Contact';
import { Login } from '../components/auth/Login';
import { Register } from '../components/auth/Register';
import { Home } from '../components/home/Home';
import { Footer } from '../components/main/Footer';
import { NavbarComp } from '../components/main/NavbarComp';
import { NavbarConsole } from '../components/main/NavbarConsole';
import { Hydroponics } from '../components/products/Hydroponics';
import { ProductView } from '../components/products/ProductView';
import { ConsoleRouter } from './ConsoleRouter';
import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { login } from '../actions/auth';
import { cookietypes } from '../cookietypes/cookietypes';

export const AppRouter = () => {

    const dispatch = useDispatch();

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const authData = useSelector(state => state.auth);
    const cookie = Cookies.get(cookietypes.authCookie);

    if (cookie && !authData.authState) {
        const cookied = JSON.parse(cookie);
        // console.log(cookied);
        const { email, name, lastname, authState } = cookied;
        dispatch(login(email, name, lastname, authState));
    }

    // console.log(cookied?.authState);

    useEffect(() => {
        //FOR SET
        if (authData.authState) {
            setIsAuthenticated(authData.authState);
        } else {
            setIsAuthenticated(false);
        }
    }, [authData]);

    console.log('Auth?: ', isAuthenticated);

    return (
        <>
            <BrowserRouter>
                <div className="fixed-header">
                    <NavbarComp />
                    {
                        isAuthenticated &&
                        <NavbarConsole />
                    }
                </div>
                <div className="main-component-content">
                    <Switch>
                        <Route exact path={["/"]} component={Home} />
                        <Route exact path={["/products/lhydro"]} component={Hydroponics} />
                        <Route exact path={["/products/lhydro/product/:id"]} component={ProductView} />
                        <Route exact path={["/about"]} component={About} />
                        <Route exact path={["/contact"]} component={Contact} />
                        <PublicRoutes exact path={["/login"]} isAuthenticated={isAuthenticated} component={Login} />
                        <PublicRoutes exact path={["/register"]} isAuthenticated={isAuthenticated} component={Register} />
                        <PrivateRoutes path={["/console/"]} isAuthenticated={isAuthenticated} component={ConsoleRouter} />
                    </Switch>
                </div>
                <Footer />
            </BrowserRouter>
        </>
    )
}
