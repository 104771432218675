import { useState } from "react"

export const useCounter = (initialState = 0) => {
    
    const [count, setCount] = useState(initialState);

    const handlePlusCount = () => {
        setCount(count + 1);
    }

    const handleMinusCount = () => {
        if(count !== 0) {
            setCount(count - 1);
        }
    }

    const handleResetcount = () => {
        setCount(initialState);
    }

    return {
        handlePlusCount,
        handleMinusCount,
        handleResetcount,
        count
    }
}
