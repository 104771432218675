import React from 'react'
import { CarouselElem } from './CarouselElem';
import { KnowledgeCenter } from './KnowledgeCenter';

// import './home.css';

export const Home = () => {
    return (
        <>
            <CarouselElem />
            <div className="home__general-container">
                {/* <div className="carousel-content"> */}
                {/* </div> */}


                {/* //Texto mitad */}
                <div className="midle-text">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do, eiusmod tempor incididunt
                    </p>
                </div>

                {/* Centro de conocimiento */}

                <KnowledgeCenter />
            </div>
        </>
    )
}
