import React, { useEffect, useState } from 'react';
import DeviceService from '../../services/DeviceService';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import outputsICons from '../../../helpers/resourceMapper';

export const ConsoleSettings = () => {

    const { id } = useParams();
    const [deviceData, setDeviceData] = useState({});
    const [readyRender, setReadyRender] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [modifyName, setModifyName] = useState(false);
    const [currentOutput, setCurrentOutput] = useState({});
    const [horaFinAvailable, setHoraFinAvailable] = useState(true);
    const [outputNumberSelected, setOutputNumberSelected] = useState(-1);

    const rangosTemperatura = {
        C: {
            min: 0,
            max: 50
        },
        F: {
            min: 32,
            max: 122
        }
    }

    const outputIconsResource = outputsICons;

    const nombresSalidas = {
        WPUMP: 'Bomba de agua',
        APUMP: 'Bomba de aire',
        COOLER: 'Ventilador',
        LIGHT: 'Illuminación',
        HEATER: 'Calefactor',
        HUMIDIFIER: 'Humidificador',
    }


    useEffect(() => {
        DeviceService.getDeviceById(id)
            .then(response => {
                // console.log(response.data);
                setDeviceData(response.data);
                setReadyRender(true);
            })
            .catch(err => {
                console.log(err);
            })
    }, [id, setDeviceData, setReadyRender]);


    const handleRetrieveData = () => {
        setCurrentOutput({});
        DeviceService.getDeviceById(id)
            .then(response => {
                // console.log(response.data);
                setDeviceData(response.data);
                setReadyRender(true);
            })
            .catch(err => {
                console.log(err);
            })
    }



    const handleShowSettings = (outputSelected) => {
        setCurrentOutput(outputSelected);
        setShowSettings(true);
    }


    //Auxiliar
    const intTimetoTime = (stringTime) => {
        let convertedTime = "";
        switch (stringTime.length) {
            case 4:
                convertedTime = stringTime.charAt(0) + stringTime.charAt(1) + ":" + stringTime.charAt(2) + stringTime.charAt(3);
                break;
            case 3:
                convertedTime = "0" + stringTime.charAt(0) + ":" + stringTime.charAt(1) + stringTime.charAt(2);
                break;

            case 2:
                convertedTime = "00:" + stringTime.charAt(0) + stringTime.charAt(1);
                break;
            case 1:
                convertedTime = "00:0" + stringTime.charAt(0);
                break;
            default:
                break;
        }
        return convertedTime;
    }

    //Auxiliar 2
    const timeToInt = (timeFormat) => {
        return parseInt(timeFormat.replace(':', ''), 10);
    }


    const handleChangeSelectedOutput = (e) => {
        setCurrentOutput({
            ...currentOutput,
            [e.target.name]: e.target.value
        });
    }

    const handleInputChangeOutputsTime = (e) => {
        setCurrentOutput({
            ...currentOutput,
            [e.target.name]: timeToInt(e.target.value)
        })
    }

    const handleInputChangeOutputsNormalValue = ({ target }) => {
        setCurrentOutput({
            ...currentOutput,
            [target.name]: parseInt(target.value)
        })
    }

    const handleInputChangeOutputNormalString = ({ target }) => {
        setCurrentOutput({
            ...currentOutput,
            [target.name]: target.value
        })
    }


    const handleDiscardSettings = () => {
        handleRetrieveData();
        setShowSettings(false);
    }


    const HandleSendSettings = (currentOutput) => {
        if (currentOutput.min >= currentOutput.max) {
            Swal.fire(
                'Precaución',
                'El valor minimo debe ser menor que el valor máximo',
                'warning'
            )
            return;
        }
        if (!horaFinAvailable) {
            currentOutput.hora_fin = -1;
        }
        let outputs = deviceData.salidas;
        for (let i = 0; i < outputs.length; i++) {
            outputs[i].modificado = false;
        }
        currentOutput.modificado = true;
        outputs[outputs.findIndex(outputs => outputs.salida === currentOutput.salida)] = currentOutput;
        setReadyRender(false);
        setShowSettings(false);
        setDeviceData({
            ...deviceData.salidas,
            [deviceData.salidas]: outputs
        });

        console.log(deviceData);

        DeviceService.postSettings(deviceData.identificador, deviceData)
            .then(() => {
                Swal.fire(
                    '¡Completado!',
                    'La configuración ha sido enviada',
                    'success'
                )

                handleDiscardSettings();
            })
            .catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Error al enviar la configuración. Intentelo nuevamente',
                })
            })
        // handleDiscardSettings();
    }

    const handleInputChangeDeviceName = ({ target }) => {
        setDeviceData({
            ...deviceData,
            [target.name]: target.value
        });
    }

    const handleSendNameChange = () => {
        DeviceService.postSettings(deviceData.identificador, deviceData)
            .then(() => {
                handleDiscardSettings();
                Swal.fire(
                    '¡Completado!',
                    'Nombre de dispositivo cambiado',
                    'success'
                )
            })
            .catch(err => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Error al enviar la configuración. Intentelo nuevamente',
                })
            })
        setModifyName(!modifyName);
    }

    const handleDiscardNameChange = () => {
        setModifyName(!modifyName);
        handleDiscardSettings();
    }



    return (
        <>
            <div className="consolesettings__general-container space-top-botton-elements">
                <div className="consolesettings__title-container">
                    <h1 className="space-top-botton-elements">Mi Huerta</h1>
                    <p className="space-top-botton-elements">Hora de conectar y personalizar</p>
                </div>
                <div className="consolesettings__config-options-general">
                    {
                        readyRender
                        &&
                        <>
                            <div className="consolesettings__config-options-title space-top-botton-elements">
                                <div className="consolesettings__config-options-p-container">
                                    <p>{deviceData.identificador}</p>
                                </div>
                                <div className="consolesettings__config-option-input-container">
                                    <input className={`input-console input${!modifyName}`} name="nombre" value={deviceData.nombre} onChange={handleInputChangeDeviceName} type="text" disabled={!modifyName} placeholder="Nombre del dispositivo" />
                                    {
                                        !modifyName ?
                                            (
                                                !showSettings
                                                &&
                                                <i onClick={() => { setModifyName(!modifyName) }} className="fas fa-pen navbar-console-button-appeareance"></i>
                                            )
                                            :
                                            <>
                                                <i onClick={() => { handleDiscardNameChange() }} className="fas fa-times navbar-console-button-appeareance"></i>
                                                <i onClick={() => { handleSendNameChange() }} className="fas fa-check navbar-console-button-appeareance"></i>
                                            </>

                                    }
                                </div>
                            </div>
                            <div className="consolesettings__config-options-inputs">
                                <Link to={`/console/garden/device/calibration/${id}`} className="no-link-appeareance">
                                    <div className="consolesettings__config-options-input-water">
                                        <p>Configurar sensor de agua</p>
                                    </div>
                                </Link>
                                <Link to={`/console/garden/device/calibration/${id}`} className="no-link-appeareance">
                                    <div className="consolesettings__config-options-input-water">
                                        <p>Llenar tanque de agua</p>
                                    </div>
                                </Link>
                            </div>
                            <div className="consolesettings__config-options-selector">
                                {
                                    deviceData.salidas.map(sal => (
                                        <div className={`consolesettings__output-selector handClickActive mod${!modifyName} salidaSel${sal.salida}sel${outputNumberSelected}`} key={sal.salida} onClick={() => { handleShowSettings(sal); setOutputNumberSelected(sal.salida); }}>
                                            <img src={process.env.PUBLIC_URL + outputIconsResource.outputsICons[sal.salida]} alt={"xlr_img"} />
                                            <p>{nombresSalidas[sal.codigo_dispositivo]}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    }

                    {
                        showSettings
                        &&
                        <>
                            <div className="consolesettings__arrow-console-conf">

                            </div>
                            <div className="consolesettings__output-configuration-container">
                                <div className="consolesettings_output-configuration-type-selector">
                                    <select name="codigo_dispositivo" value={currentOutput.codigo_dispositivo} onChange={handleChangeSelectedOutput}>
                                        <option value="WPUMP">Bomba de agua</option>
                                        <option value="APUMP">Bomba de aire</option>
                                        <option value="COOLER">Ventilador</option>
                                        <option value="LIGHT">Illuminación</option>
                                        <option value="HEATER">Calefactor</option>
                                        <option value="HUMIDIFIER">Humidificador</option>
                                    </select>
                                </div>
                                {
                                    (currentOutput.codigo_dispositivo === 'WPUMP' || currentOutput.codigo_dispositivo === 'APUMP') &&
                                    <div className="consolesettings_pump-selected">
                                        <div className="consolesettings__pump-selected-row1">
                                            <label>Hora de inicio:</label>
                                            <input name="hora_inicio" type="time" value={intTimetoTime(String(currentOutput.hora_inicio))} onChange={handleInputChangeOutputsTime} placeholder="Hora de inicio" />

                                            <input type="checkbox" required={false} className="check-form" onChange={() => setHoraFinAvailable(!horaFinAvailable)} />

                                            <label>Hora de finalización:</label>
                                            <input className={`mod${!horaFinAvailable}`} name="hora_fin" type="time" value={intTimetoTime(String(currentOutput.hora_fin))} disabled={horaFinAvailable} onChange={handleInputChangeOutputsTime} placeholder="Hora de finalización" />
                                            <label>Repetición:</label>
                                            <input name="repeticion" type="time" value={intTimetoTime(String(currentOutput.repeticion))} onChange={handleInputChangeOutputsTime} placeholder="Repetir cada" />
                                            <label>Duración:</label>
                                            <input name="duracion" type="time" value={intTimetoTime(String(currentOutput.duracion))} onChange={handleInputChangeOutputsTime} placeholder="Durante" />
                                        </div>
                                    </div>
                                }

                                {
                                    (currentOutput.codigo_dispositivo === 'LIGHT') &&
                                    <div className="consolesettings_light-selected">
                                        <div className="consolesettings__pump-selected-row1">
                                            <label>Hora de inicio:</label>
                                            <input name="hora_inicio" type="time" value={intTimetoTime(String(currentOutput.hora_inicio))} onChange={handleInputChangeOutputsTime} placeholder="Hora de inicio" />
                                            <label>Hora de finalización:</label>
                                            <input name="hora_fin" type="time" value={intTimetoTime(String(currentOutput.hora_fin))} onChange={handleInputChangeOutputsTime} placeholder="Hora de finalización" />
                                        </div>
                                    </div>
                                }

                                {
                                    (currentOutput.codigo_dispositivo === 'COOLER' || currentOutput.codigo_dispositivo === 'HEATER') &&
                                    <div className="consolesettings_cooler-heater-selected">
                                        <div className="consolesettings__pump-selected-row1">
                                            <label>Unidad:</label>
                                            <select name="unidad" value={currentOutput.unidad} onChange={handleInputChangeOutputNormalString}>
                                                <option value="C">Centígrados (C)</option>
                                                <option value="F">Farenheite (F)</option>
                                            </select>

                                            {
                                                currentOutput.unidad === 'C'
                                                    ?
                                                    <>
                                                        <label>Valor minimo:</label>
                                                        <input name="min" type="number" min={rangosTemperatura.C.min} max={rangosTemperatura.C.max} value={currentOutput.min} onChange={handleInputChangeOutputsNormalValue} placeholder="Min" />
                                                        <label>Valor máximo:</label>
                                                        <input name="max" type="number" min={rangosTemperatura.C.min} max={rangosTemperatura.C.max} value={currentOutput.max} onChange={handleInputChangeOutputsNormalValue} placeholder="Max" />
                                                    </>
                                                    :
                                                    <>
                                                        <label>Valor minimo:</label>
                                                        <input name="min" type="number" min={rangosTemperatura.F.min} max={rangosTemperatura.F.max} value={currentOutput.min} onChange={handleInputChangeOutputsNormalValue} placeholder="Min" />
                                                        <label>Valor máximo:</label>
                                                        <input name="max" type="number" min={rangosTemperatura.F.min} max={rangosTemperatura.F.max} value={currentOutput.max} onChange={handleInputChangeOutputsNormalValue} placeholder="Max" />
                                                    </>
                                            }

                                        </div>
                                    </div>
                                }

                                {
                                    (currentOutput.codigo_dispositivo === 'HUMIDIFIER') &&
                                    <div className="consolesettings_cooler-heater-selected">
                                        <div className="consolesettings__pump-selected-row1">
                                            <label>Valor minimo %:</label>
                                            <input name="min" type="number" min={20} max={80} value={currentOutput.min} onChange={handleInputChangeOutputsNormalValue} placeholder="Min" />
                                            <label>Valor máximo %:</label>
                                            <input name="max" type="number" min={20} max={80} value={currentOutput.max} onChange={handleInputChangeOutputsNormalValue} placeholder="Max" />
                                        </div>
                                    </div>
                                }

                                <div className="consolesettings__pump-selected-row2">
                                    <button onClick={handleDiscardSettings}>Descartar</button>
                                    <button>Probar</button>
                                    <button onClick={() => { HandleSendSettings(currentOutput) }}>Guardar</button>
                                </div>
                            </div>
                        </>
                    }

                </div>
            </div>
        </>
    )
}
