import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';
// import UserServices from '../services/UserServices';
// import validator from 'validator';
// import Swal from 'sweetalert2';
// import './authentication.css';

export const Contact = () => {

    // const history = useHistory();

    const [ formVals, handleInputChange ] = useForm({
        email: '',
        name: '',
        lastname: '',
        phone : '',
        message : ''

    });

    const handleSubmit = (e) => {
        e.preventDefault();
        //To be continue
        console.log(formVals);
    }

    return (
        <>
            <div className="login-container">
                <div className="login-titulo">
                    <h1>Contáctanos</h1>
                </div>
                <p className="login-parafo">Dejanos tu mensaje. Para nosotros es muy importante tu opinión. Pondremos a tu disposición los mejores asesores</p>
                
                <div className="login-form-container">
                    <form className="login-form-components" onSubmit={handleSubmit}>
                        <label className="nombre-form-title" >Correo</label>
                        <div>
                            <i className="fas fa-at"></i>
                            <input type="email" required={true} className="input-form" autoComplete="new-off" name="email" onChange={handleInputChange} />
                        </div>
                        <label className="nombre-form-title" >Nombres</label>
                        <div>
                            <i className="fas fa-pen"></i>
                            <input type="text" required={true} className="input-form" autoComplete="new-off" name="name" onChange={handleInputChange} />
                        </div>
                        <label className="nombre-form-title" >Apellidos</label>
                        <div>
                            <i className="fas fa-pen"></i>
                            <input type="text" required={true} className="input-form" autoComplete="new-off" name="lastname" onChange={handleInputChange} />
                        </div>
                        <label className="nombre-form-title" >Teléfono</label>
                        <div>
                            <i className="fas fa-phone"></i>
                            <input type="number" required={true} className="input-form" autoComplete="new-off" name="phone" onChange={handleInputChange} />
                        </div>
                        <label className="nombre-form-title" >Mensaje</label>
                        <div>
                            <i className="far fa-envelope"></i>
                            <textarea required={true} className="input-form" autoComplete="new-off" name="message" onChange={handleInputChange} />
                        </div>
                        <div className="form-privacy-policies">
                            <input type="checkbox" required={true} className="check-form" />
                            <p>
                                Acepto las políticas de tratamiento y protección de datos personales de DesignDLR.  
                                <Link to="/privacypolicies" >  Ver aquí las políticas de privacidad</Link>
                            </p>
                        </div>
                        <button className="btn btn-primary">Registrame</button>
                    </form>
                </div>
            </div>

        </>
    )
}
