import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';
import UserServices from '../services/UserServices';
import validator from 'validator';
import Swal from 'sweetalert2';
// import './authentication.css';

export const Register = () => {

    const history = useHistory();

    const [ formVals, handleInputChange ] = useForm({
        email: '',
        name: '',
        lastname: '',
        password: '',
        password2: '',

    });

    const handleSubmit = (e) => {
        e.preventDefault();
        validator.equals(formVals.password, formVals.password2) 
        ?
        UserServices.registerUser(formVals)
        .then(response => {
            if(response.data.STATUS === 'User created succesfully'){
                Swal.fire(
                    '¡Completado!',
                    'Registro completado',
                    'success'
                )
                history.push('/login');
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Error al realizar el registro. Por favor intentelo de nuevo',
                  })
            }
        }).catch((error) => {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Por favor verifique su conexión a internet e intente nuevamente',
              })
        })
        :
        Swal.fire({
            icon: 'warning',
            title: 'Precaución',
            text: 'Las contraseñas no coinciden',
          })
    }

    return (
        <>
            <div className="login-container">
                <div className="login-titulo">
                    <h1>¡Bienvenido! Registrate aquí</h1>
                </div>
                <p className="login-parafo">Registrate en DesignDLR para realizar tus compras y configurar tus dispositivos.</p>
                <p className="login-parafo">Sí tienes cuenta <Link to="/login" className="login-link-register">Inicia sesión aquí</Link></p>
                <div className="login-form-container">
                    <form className="login-form-components" onSubmit={handleSubmit}>
                        <label className="nombre-form-title" >Correo</label>
                        <div>
                            <i className="fas fa-at"></i>
                            <input type="email" required={true} className="input-form" autoComplete="new-off" name="email" onChange={handleInputChange} />
                        </div>
                        <label className="nombre-form-title" >Nombres</label>
                        <div>
                            <i className="fas fa-pen"></i>
                            <input type="text" required={true} className="input-form" autoComplete="new-off" name="name" onChange={handleInputChange} />
                        </div>
                        <label className="nombre-form-title" >Apellidos</label>
                        <div>
                            <i className="fas fa-pen"></i>
                            <input type="text" required={true} className="input-form" autoComplete="new-off" name="lastname" onChange={handleInputChange} />
                        </div>
                        <label className="nombre-form-title" >Contraseña</label>
                        <div>
                            <i className="fas fa-lock"></i>
                            <input type="password" required={true} className="input-form" autoComplete="new-off" name="password" onChange={handleInputChange} />
                        </div>
                        <label className="nombre-form-title" >Confirmar contraseña</label>
                        <div>
                            <i className="fas fa-lock"></i>
                            <input type="password" required={true} className="input-form" autoComplete="new-off" name="password2" onChange={handleInputChange} />
                        </div>
                        <div className="form-privacy-policies">
                            <input type="checkbox" required={true} className="check-form" />
                            <p>
                                Acepto las políticas de tratamiento y protección de datos personales de DesignDLR.  
                                <Link to="/privacypolicies" >  Ver aquí las políticas de privacidad</Link>
                            </p>
                        </div>
                        <button className="btn btn-primary">Registrame</button>
                    </form>
                </div>
            </div>

        </>
    )
}
