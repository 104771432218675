import React, { useState, useEffect } from 'react';
import ProductService from '../services/ProductService';
import ProductGridItem from './ProductGridItem';
// import './products.css';

export const Hydroponics = () => {

    const [products, setProducts] = useState([]);
    const [returnProd, setReturnProd] = useState(false);

    useEffect(() => {
        retrieveProducts();
    }, []);

    const retrieveProducts = () => {
        ProductService.getAll()
            .then(response => {
                setProducts(response.data);
                console.log(response.data);
                setReturnProd(true);
            })
            .catch(err => {
                console.log(err);
            })
    }

    // const refreshProductList = () => {
    //     retrieveProducts();
    // }



    return (
        <>
            <div className="container-products">
                <h1 className="title-products-hydroponic-line">Linea Hidropónica</h1>
                {
                    !returnProd ?
                        <>
                            <div className="row">
                                <div className="wrapper">
                                    <h3> </h3>
                                    <div className="card-loader card-loader--tabs"></div>
                                </div>
                                <div className="wrapper">
                                    <h3> </h3>
                                    <div className="card-loader card-loader--tabs"></div>
                                </div>
                                <div className="wrapper">
                                    <h3> </h3>
                                    <div className="card-loader card-loader--tabs"></div>
                                </div>
                            </div>
                        </>
                        : ''
                }
                <div className="row">

                    {
                        returnProd && products.map(product => (

                            <div className="col-4" key={product.id}>
                                <ProductGridItem
                                    {...product}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}
