import React, { useEffect, useState } from 'react';
import DeviceService from '../services/DeviceService';
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';

export const MyGardenConsole = () => {

    const authData = useSelector(state => state.auth);

    const [deviceList, setDeviceList] = useState([]);

    useEffect(() => {
        DeviceService.getDeviceList(authData.email)
            .then(response => {
                setDeviceList(response.data);
            })
            .catch(err => {
                console.log(err);
            })
    }, [authData.email, setDeviceList]);


    return (
        <>
            <div className="garden__container-general space-top-botton-elements">
                {
                    deviceList.length === 0 ?
                        (
                            <div className="garden__first-window">
                                <h1 className="space-top-botton-elements">Mi Huerta</h1>
                                <p className="space-top-botton-elements">Hola, aquí puedes registrar tus dispositivos hidropónicos y administrarlos</p>
                                <button className="general-buttons-appeareance space-top-botton-elements">Registrar dispositivo</button>
                            </div>
                        ) :
                        (
                            <>

                                <div className="garden__device-list-container space-top-botton-elements">
                                    <div className="garden__device-static-title">
                                        <h1 className="general-title-appeareance">Mi Huerta</h1>
                                        <p>Administrar mis dispositivos hidropónicos</p>
                                    </div>

                                    <div className="garden__device-list">
                                        {
                                            deviceList.map(dev => (
                                                <div className="garden__device-card-device handClickActive" key={dev.identificador}>
                                                    <Link to={`/console/garden/device/${dev.identificador}`} className="no-link-appeareance">
                                                        <div className="garden__device-card-image">
                                                            <img src="https://www.marlohydroponics.com/wp-content/uploads/2020/07/hydroponics_why_a_good_idea.jpg" alt={dev.nombre} />
                                                        </div>
                                                        <div className="garden__device-card-name">
                                                            <h5>{dev.nombre}</h5>
                                                        </div>
                                                    </Link>
                                                </div>
                                            ))
                                        }
                                        <div className="garden__device-card-device handClickActive">
                                            <div className="garden__device-card-image">
                                                <img src="https://cdn3.iconfinder.com/data/icons/buttons/512/Icon_31-512.png" alt="add-button" />
                                            </div>
                                            <div className="garden__device-card-name">
                                                <h5>Agregar nuevo dispositivo</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        )
                }

            </div>
        </>
    )
}
