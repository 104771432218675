import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import DeviceService from '../../services/DeviceService';

export const WaterSensorConsole = () => {

    const { id } = useParams();
    const [deviceData, setDeviceData] = useState({});
    const [readyCalibrate, setReadyCalibrate] = useState(false);
    const [currentCalibration, setCurrentCalibration] = useState(0);

    const history = useHistory();

    useEffect(() => {
        DeviceService.getDeviceById(id)
            .then(response => {
                // console.log(response.data);
                setDeviceData(response.data);
                setReadyCalibrate(true);
            })
            .catch(err => {
                console.log(err);
            })
    }, [id, setDeviceData, setReadyCalibrate]);

    const handleCalibrateNow = () => {
        setCurrentCalibration(currentCalibration + 1);
    }

    const handleMarkLower = () => {
        setDeviceData(deviceData.estado = {
            ...deviceData.estado,
            tipo: 'calibrate'
        })

        console.log(deviceData);

        DeviceService.postSettings(deviceData.identificador, deviceData)
            .then(() => {
                const i1 = setInterval(() => {
                    
                    DeviceService.getDeviceById(id)
                        .then(response => {
                            setDeviceData(response.data);
                            const testState = response.data.estado.test;
                            if (testState === "Read1") {
                                console.log('Received Read1');
                                clearInterval(i1);
                                setCurrentCalibration(currentCalibration + 1);
                                return;
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        })
                }, 5000);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleMarkHigher = () => {
        setDeviceData(deviceData.estado = {
            ...deviceData.estado,
            test: 'MAX',
            tipo : 'activo'
        })

        console.log(deviceData);

        DeviceService.postSettings(deviceData.identificador, deviceData)
            .then(() => {
                    const i2 = setInterval(() => {
                        
                        DeviceService.getDeviceById(id)
                            .then(response => {
                                setDeviceData(response.data);
                                const testState = response.data.estado.test;
                                if (testState === "Read2") {
                                    console.log('Received read 2');
                                    clearInterval(i2);
                                    setCurrentCalibration(currentCalibration + 1);
                                    return;
                                }
                            })
                            .catch(err => {
                                console.log(err);
                            })
                    }, 5000);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleCancelCalibrate = () => {
        setDeviceData(deviceData.estado = {
            ...deviceData.estado,
            test: "CANCEL"
        });

        DeviceService.postSettings(deviceData.identificador, deviceData)
            .then(() => {
                DeviceService.getDeviceById(id)
                    .then(() => {

                        history.push(`/console/garden/device/${deviceData.identificador}`);
                    })
                    .catch(err => {
                        console.log(err);
                    })
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleFinishCalibration = () => {
        setDeviceData(deviceData.estado = {
            ...deviceData.estado,
            test: null
        });

        DeviceService.postSettings(deviceData.identificador, deviceData)
            .then(() => {
                DeviceService.getDeviceById(id)
                    .then(() => {

                        history.push(`/console/garden/device/${deviceData.identificador}`);
                    })
                    .catch(err => {
                        console.log(err);
                    })
            })
            .catch(err => {
                console.log(err);
            })

    }

    return (
        <>
            <div className="watersensor__console-general-container">
                {
                    currentCalibration === 0
                    &&
                    <>
                        <h3>Configuración de sensor de agua</h3>
                        <p>
                            Configure aquí su sensor de agua. Marque aquí los limites máximos y mínimos del sensor de agua para poder identificar cual es el nivel de su tanque de agua.
                        </p>
                        <p>
                            Para iniciar, presione el botón comenzar y siga las instrucciones de forma secuencial
                        </p>
                        <button onClick={() => { handleCalibrateNow() }} className="btn btn-primary" disabled={!readyCalibrate}>Comenzar configuración</button>
                    </>
                }
                {
                    currentCalibration === 1
                    &&
                    <>
                        <h3>¡Comencemos la configuración!</h3>
                        <p>Ubique el sensor en el tanque contenedor de forma perpendilar en la parte superior con el indicador apuntando hacia abajo. Luego, empiece a llenando la parte inferior de agua hasta que la bomba este cubierta. Luego, presione Marcar Mínimo. Esto le indicara al sistema cual es la medida minima del tanque y le informara cuando este desocupandose.</p>
                        <button className="btn btn-primary" onClick={() => { handleMarkLower() }}>Marcar Mínimo</button>
                        <button className="btn btn-primary" onClick={() => { handleCancelCalibrate() }}>Cancelar</button>
                    </>
                }
                {
                    currentCalibration === 2
                    &&
                    <>
                        <h3>¡Ahora vamos a configurar el nivel máximo!</h3>
                        <p>Llene el tanque de agua sin que el agua toque el sensor. Luego, presione el bóton Marcar Máximo</p>
                        <button className="btn btn-primary" onClick={() => { handleMarkHigher() }}>Marcar Máximo</button>
                        <button className="btn btn-primary" onClick={() => { handleCancelCalibrate() }}>Cancelar</button>
                    </>
                }
                {
                    currentCalibration === 3
                    &&
                    <>
                        <h3>¡La calibración ha terminado!</h3>
                        <p>Ahora su dispositivo le notificara cuando los niveles de agua lleguen al mínimo</p>
                        <button className="btn btn-primary" onClick={() => { handleFinishCalibration() }}>Terminar</button>
                    </>
                }
            </div>
        </>
    )
}
