import UserServices from "../components/services/UserServices";
import { types } from "../types/types";
import Cookies from 'js-cookie';
import { cookietypes } from '../cookietypes/cookietypes';
import Swal from 'sweetalert2';


export const loginWithCreds = (formVals) => {
    return (dispatch) => {
        UserServices.authUser(formVals)
            .then(response => {
                const { STATUS, DATA } = response.data;
                if (STATUS === 'LOGGED') {
                    // console.log(DATA);
                    const { email, name, lastname } = DATA;
                    const authState = true;
                    dispatch(login(email, name, lastname, authState));
                    const cookieObj = { email, name, lastname, authState };
                    Cookies.set(cookietypes.authCookie, JSON.stringify(cookieObj));
                    Swal.fire({
                        position: 'top-end',
                        title: `¡Hola de nuevo ${name}!`,
                        showConfirmButton: false,
                        timer: 1500
                    })
                } else {
                    // console.log(STATUS);
                    Swal.fire(
                        'Oops',
                        'Por favor verifíque su usuario y contraseña e intente de nuevo',
                        'warning'
                    )
                }
            }).catch(e => {
                console.log(e.message);
            })
    }
}

export const updateUserData = (formVals) => {
    return (dispatch) => {
        UserServices.updateUserProfile(formVals.email, formVals)
            .then(response => {
                const {STATUS} = response.data;
                if (STATUS === 'USER_UPDATED_SUCCESSFULLY') {
                    const authState = true;
                    const {email, name, lastname} = formVals;
                    Swal.fire(
                        '¡Completado!',
                        'El usuario ha sido actualizado',
                        'success'
                    )
                    dispatch(login(email, name, lastname, authState));
                    const cookieObj = { email, name, lastname, authState };
                    Cookies.set(cookietypes.authCookie, JSON.stringify(cookieObj));
                    // console.log(cookieObj);
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Error al actualizar el registro. Intentelo nuevamente',
                    })
                }
            }).catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Por favor verifique su conexión a internet e intente nuevamente',
                })
            })
    }
}

export const login = (email, name, lastname, authState) => {
    return {
        type: types.login,
        payload: { email, name, lastname, authState }
    }
}

export const startLogout = () => {
    return (dispatch) => {
        Cookies.remove(cookietypes.authCookie);
        dispatch(logout());
    }
}


export const logout = () => {
    return {
        type: types.logout
    }

}