import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConsoleSettings } from '../components/console/garden/ConsoleSettings';
import { WaterSensorConsole } from '../components/console/garden/WaterSensorConsole';
import { HomeConsole } from '../components/console/HomeConsole';
import { Logout } from '../components/console/Logout';
import { MessagesConsole } from '../components/console/MessagesConsole';
import { MyGardenConsole } from '../components/console/MyGardenConsole';
import { OrdersConsole } from '../components/console/OrdersConsole';
import { ProfileConsole } from '../components/console/ProfileConsole';
import { ShoppingConsole } from '../components/console/ShoppingConsole';


export const ConsoleRouter = () => {
    return (
        <div className="console__general-container">
            <div className="console__packet-components">
                <Switch>
                    <Route exact path="/console/home" component={HomeConsole} />
                    <Route exact path="/console/profile" component={ProfileConsole} />
                    <Route exact path="/console/garden" component={MyGardenConsole} />
                    <Route exact path="/console/garden/device/:id" component={ConsoleSettings} />
                    <Route exact path="/console/messages" component={MessagesConsole} />
                    <Route exact path="/console/orders" component={OrdersConsole} />
                    <Route exact path="/console/shoppingcart" component={ShoppingConsole} />
                    <Route exact path="/console/garden/device/calibration/:id" component={WaterSensorConsole} />
                    <Route exact path="/console/logout" component={Logout} />
                </Switch>
            </div>
        </div>
    )
}
