import React from 'react';
import { Link } from 'react-router-dom';

export const NavbarComp = () => {

    // const [subMenuProducts, setSubMenuProducts] = useState(false);

    // const [subMenuUsers, setSubMenuUsers] = useState(false);

    // const handleClickProducts = (e) => {
    //     e.preventDefault();
    //     setSubMenuProducts(!subMenuProducts);
    // }

    // const handleClickUser = (e) => {
    //     e.preventDefault();
    //     setSubMenuUsers(!subMenuUsers);
    // }

    return (
        <>
            <div className="main-component-content-header header__container">
                <div className="header__icon">
                    <Link to="/">
                        <img src={process.env.PUBLIC_URL + '/assets/logo1-encabezado.png'} alt="head-icon" />
                    </Link>
                </div>
                <div className="header__main-selector">
                    <nav>
                        <Link className="navbar-button-appeareance" to="/">Inicio</Link>
                        <Link className="navbar-button-appeareance" to="/products/lhydro">Productos</Link>
                        {/* <Link to="#" onClick={handleClickProducts}>Productos</Link>
                        {
                            subMenuProducts
                            &&
                            <nav>
                                <Link to="/products/lhydro">Productos</Link>
                            </nav>
                        } */}

                        <Link className="navbar-button-appeareance" to="/about">Conócenos</Link>
                        <Link className="navbar-button-appeareance" to="/contact">Contactanos</Link>
                        <Link className="navbar-users-icon" to="/login"><i className="far fa-user-circle"></i></Link>
                    </nav>
                </div>
                
            </div>
            <div className="header__bottom-line"></div>
            
        </>
    )
}
