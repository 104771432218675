import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from '../../hooks/useForm';
import UserServices from '../services/UserServices';
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator';
import Swal from 'sweetalert2';
import { updateUserData } from '../../actions/auth';
// import './authentication.css';

export const ProfileConsole = () => {

    const history = useHistory();
    const dispatch = useDispatch();
    const authData = useSelector(state => state.auth);
    const initValsPassword = {
        password: '',
        password2: '',
        password2confirm: ''
    }
    const [passwordValues, setPasswordValues] = useState(initValsPassword);

    const [passwordUpdateForm, setPasswordUpdateForm] = useState(false);

    const initialVals = {
        email: authData.email,
        name: authData.name,
        lastname: authData.lastname,
    }

    const [formVals, handleInputChange, reset] = useForm(initialVals);

    const handleSubmit = () => {
        // e.preventDefault();
        dispatch(updateUserData(formVals));
        setTimeout(() => {
            history.push('/console/home');
        }, 1000);
    }

    const handleReset = (e) => {
        e.preventDefault();
        reset();
        Swal.fire({
            icon: 'info',
            position: 'top-end',
            title: 'Edición descartada',
            showConfirmButton: false,
            timer: 1500
        })
    }

    const handleSubmitPassword = () => {
        if(validator.isEmpty(passwordValues.password) || validator.isEmpty(passwordValues.password2) || validator.isEmpty(passwordValues.password2confirm) ){
            return Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Por favor ingrese la información en todos los campos',
            })
        }
        if(validator.isStrongPassword(passwordValues.password2)){
            return Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'La nueva contraseña debe tener un mínimo de 8 caracteres y al menos una letra mayúscula',
            })
        }

        if(passwordValues.password2 !== passwordValues.password2confirm){
            
            return Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'Las nuevas contraseñas no coinciden',
            })
        }

        if(passwordValues.password === passwordValues.password2){
            
            return Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: 'La nueva contraseña debe ser diferente a la anterior',
            })
        }
        console.log(formVals.email);
        UserServices.updateUserPassword(formVals.email, passwordValues)
        
                .then(response => {
                    // console.log(response);
                    const { STATUS } = response.data;
                    if (STATUS === 'USER_PASSWORD_UPDATED') {
                        Swal.fire(
                            '¡Completado!',
                            'Contraseña actualizada',
                            'success'
                        )
                        setPasswordUpdateForm(false)
                    } else if (STATUS === 'PASSWORD_NOT_VALID') {
                        Swal.fire(
                            'Error',
                            'La contraseña actual no coincide',
                            'warning'
                        )
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Error al actualizar el registro. Intentelo nuevamente',
                        })
                    }
                }).catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Por favor verifique su conexión a internet e intente nuevamente',
                    })
                })
    }

    const handleResetPasswordChange = () => {
        setPasswordValues(initValsPassword);
        setPasswordUpdateForm(true);
    }

    const handleInputChangePassword = ({ target }) => {
        setPasswordValues({
            ...passwordValues,
            [target.name]: target.value
        });
    }

    return (
        <>
            <div className="login-container">
                {
                    !passwordUpdateForm ?
                        <>
                            <div className="login-titulo">
                                <h1>Modificar usuario</h1>
                            </div>
                            <div className="login-form-container">
                                <div className="login-form-components">
                                    <label className="nombre-form-title" >Correo</label>
                                    <div>
                                        <i className="fas fa-at"></i>
                                        <input type="email" required={true} className="input-form" autoComplete="new-off" value={formVals.email} name="email" disabled={true} onChange={handleInputChange} />
                                    </div>
                                    <label className="nombre-form-title" >Nombres</label>
                                    <div>
                                        <i className="fas fa-pen"></i>
                                        <input type="text" required={true} className="input-form" autoComplete="new-off" value={formVals.name} name="name" onChange={handleInputChange} />
                                    </div>
                                    <label className="nombre-form-title" >Apellidos</label>
                                    <div>
                                        <i className="fas fa-pen"></i>
                                        <input type="text" required={true} className="input-form" autoComplete="new-off" value={formVals.lastname} name="lastname" onChange={handleInputChange} />
                                    </div>
                                    <div className="login-form-row-options">
                                        <button className="btn btn-primary" onClick={handleReset}>Descartar</button>
                                        <button className="btn btn-primary" onClick={() => { handleSubmit() }}>Guardar</button>
                                    </div>
                                    <button className="btn btn-primary" onClick={() => { handleResetPasswordChange() }}>Modificar contraseña</button>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="login-titulo">
                                <h1>Modificar contraseña</h1>
                            </div>
                            <div className="login-form-container">
                                <div className="login-form-components">
                                    <label className="nombre-form-title" >Contraseña actual</label>
                                    <div>
                                        <i className="fas fa-lock"></i>
                                        <input type="password" required={true} className="input-form" autoComplete="new-off" name="password" value={passwordValues.password} onChange={handleInputChangePassword} />
                                    </div>
                                    <label className="nombre-form-title" >Nueva contraseña</label>
                                    <div>
                                        <i className="fas fa-lock"></i>
                                        <input type="password" required={true} className="input-form" autoComplete="new-off" name="password2" value={passwordValues.password2} onChange={handleInputChangePassword} />
                                    </div>
                                    <label className="nombre-form-title" >Confirmar contraseña</label>
                                    <div>
                                        <i className="fas fa-lock"></i>
                                        <input type="password" required={true} className="input-form" autoComplete="new-off" name="password2confirm" value={passwordValues.password2confirm} onChange={handleInputChangePassword} />
                                    </div>
                                    <div className="login-form-row-options">
                                        <button className="btn btn-primary" onClick={() => { setPasswordUpdateForm(false) }}>Descartar</button>
                                        <button onClick={() => { handleSubmitPassword() }} className="btn btn-primary">Guardar</button>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>

        </>
    )
}
