import React from 'react';
import { KnowledgeCenter } from '../home/KnowledgeCenter';


export const HomeConsole = () => {


    return (
        <>
            <div className="homeconsole__container-general">
                <div className="homeconsole__banner-image">
                    <img src="https://i.pinimg.com/originals/3f/d5/72/3fd57295f35590888c27ec2175fb81be.jpg" alt="home_banner" />
                </div>
                <div className="homeconsole__body-content">
                    <h3>Lo que debes saber</h3>
                    <p>
                        ¡Bienvenido!, aquí te enseñaremos brevemente donde y como administrar tus hidropónicos DLR.
                        Si deseas descargar el manual específico de tu dispositivo ingresa en el siguiente enlace.
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corporis quae nemo iure voluptatibus ipsa repudiandae et odit praesentium, at, asperiores pariatur. Velit libero asperiores facilis aliquam eius. Iusto, dolorem excepturi!
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis quae libero as per dolor sit am  et consectetur adipisicing elit
                    </p>
                    
                </div>
                <KnowledgeCenter />
            </div>
        </>
    )
}
