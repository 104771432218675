import { AppRouter } from "./routers/AppRouter";
import {Provider} from 'react-redux';
import { store } from "./store/store";

export const DesignDlrApp = () => {
    return (
        <Provider store={store}>
            <AppRouter />
        </Provider>
    )
}
