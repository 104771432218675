import { useDispatch } from "react-redux"
import { startLogout } from "../../actions/auth";


export const Logout = () => {
    const dispatch = useDispatch();
    dispatch(startLogout());
    return(<></>);
}

