import React, { useEffect, useState } from 'react'
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import MessagesService from '../services/MessagesService';

export const NavbarConsole = () => {

    const authData = useSelector(state => state.auth);
    const [messageCountDot, setMessageCountDot] = useState(0);

    useEffect(() => {
        MessagesService.getMessageByOwner(authData.email)
            .then(response => {
                setMessageCountDot(Object.keys(response.data).length);
            })
            .catch(err => {
                console.log(err);
            })
    }, [authData.email, messageCountDot]);
    return (
        <>
        <div className="navbar__console-container">
            <div className="navbar__console-content main-component-content">
                <div className="navbar__console-user-name">
                    <p>{authData.name} {authData.lastname}</p>
                </div>
                <div className="navbar__console-options">
                    <nav>
                        <Link className="navbar-console-button-appeareance" to="/console/home" >¡Comencemos!</Link>
                        <Link className="navbar-console-button-appeareance" to="/console/profile" >Mi perfil</Link>
                        <Link className="navbar-console-button-appeareance" to="/console/garden" >Mi huerta</Link>
                        <Link className="navbar-console-button-appeareance" to="/console/messages" >Mensajes</Link>{messageCountDot > 0 && <i className={`fas fa-circle notification-dot-navbar`}></i>}
                        <Link className="navbar-console-button-appeareance" to="/console/orders" >Mis ordenes</Link>
                        <Link className="navbar-console-button-appeareance" to="/console/shoppingcart" ><i className="fas fa-shopping-cart"></i></Link>
                        <Link className="navbar-console-button-appeareance" to="/console/logout" >Cerrar sesión <i className="fas fa-sign-out-alt"></i></Link>
                    </nav>
                </div>
            </div>
        </div>
        </>
    )
}
