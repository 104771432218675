import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCounter } from '../../hooks/useCounter';
import ProductService from '../services/ProductService';
// import './productview.css';

export const ProductView = () => {

    const [product, setProduct] = useState({});
    const [returnProd, setReturnProd] = useState(false);
    const { id } = useParams();

    let galleryMap;

    const { handlePlusCount, handleMinusCount, count } = useCounter();


    useEffect(() => {
        ProductService.getById(id)
            .then(response => {
                setProduct(response.data);
                // console.log(JSON.parse(response.data.gallery));
                // console.log(gallery);
                setReturnProd(true);
            })
            .catch(err => {
                console.log(err);
            })
    }, [setProduct, setReturnProd, id]);



    if (returnProd) {
        galleryMap = JSON.parse(product.gallery);
    }

    return (
        <>
            <div className="container-products">
                {
                    returnProd ?
                        (
                            <div className="product-catalog-container" key={product.id}>
                                <div className="product-catalog-banner">
                                    <img src={product.banner_img} alt={product.name} />
                                </div>
                                <div className="product-catalog-buy-component">
                                    <div className="product-catalog-buy-component-cell1">
                                        <p className="price-product add-product">${product.price}</p>
                                        <button className="btn btn-outline-dark" onClick={handleMinusCount}>-</button>
                                        <h3 className="product-counter">{count}</h3>
                                        <button className="btn btn-outline-dark" onClick={handlePlusCount}>+</button>
                                        <button className="btn btn-primary add-product" >Añadir al carrito</button>
                                    </div>
                                </div>
                                <div className="product-catalog-review">
                                    <div className="row1">
                                        <h1 className="name-product-catalog">{product.name}</h1>
                                    </div>
                                    <p className="product-catalog-description">
                                        {
                                            product.description
                                        }
                                    </p>
                                </div>
                                <div className="product-catalog-applications">
                                    
                                </div>
                                <div className="product-catalog-gallery">
                                    {
                                        galleryMap.gallery.map((gallery, index) => (
                                            <img key={index} className="product-catalog-gallery" src={gallery} alt={gallery} />
                                        ))
                                    }
                                </div>
                            </div>
                        )
                        :
                        'Loading...'
                }
            </div>
        </>
    )
}
