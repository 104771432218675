//Banner carrousel images
const carouselRes = [
    '/assets/banner-carrousel/recurso1.png',
    '/assets/banner-carrousel/recurso2.png',
    '/assets/banner-carrousel/recurso3.png',
];

const outputsICons = [
    '/assets/output-icon/salida1.png',
    '/assets/output-icon/salida2.png',
    '/assets/output-icon/salida3.png',
    '/assets/output-icon/salida4.png',
    '/assets/output-icon/salida5.png',
    '/assets/output-icon/salida6.png',
]

const exportedObjects = {
    carouselRes,
    outputsICons,
}

export default exportedObjects;
