import http from '../axios/http-common';


const getDeviceList = (id) => {
    return http.get(`device/list/${id}`);
}

const getDeviceById = (id) => {
    return http.get(`/device/cfg/${id}`);
}

const postSettings = (id, settings) => {
    return http.put(`device/${id}`,settings);
}

const exportedObject = {
    getDeviceList,
    getDeviceById,
    postSettings,
};

export default exportedObject;