import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import MessagesService from '../services/MessagesService';

export const MessagesConsole = () => {

    const authData = useSelector(state => state.auth);
    const [showMessagesList, setShowMessagesList] = useState(false);
    const [messageList, setMessageList] = useState([]);

    useEffect(() => {
        MessagesService.getMessageByOwner(authData.email)
            .then(response => {
                setShowMessagesList(true);
                setMessageList(response.data);
            })
            .catch(err => {
                console.log(err);
            })
    }, [authData.email]);

    var dateObj = new Date();
    var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
    var date = ('0' + dateObj.getDate()).slice(-2);
    var year = dateObj.getFullYear();
    var shortDate = year + '-' + month + '-' + date;

    return (
        <>
            <div className="messagesconsole__general-container">
                <div className="messagesconsole__general-header-content">
                    <h1 className="space-top-botton-elements general-title-appeareance">Mensajes</h1>
                    <p className="space-top-botton-elements general-p-format">Notificaciones, actualizaciones, noticias y mas</p>
                </div>
                <div className="messagesconsole__general-message-item-container">
                    {
                        showMessagesList &&

                        messageList.map(message => (
                            <div className={`messageconsole__message-item`} key={message.id}>
                                <div className="row-message-item">
                                    <i className={`fas fa-circle notification-dot-item ${message.type_log}`}></i>
                                    <p className="general-p-format">{message.message}</p>
                                    {/* <Link to="/console/message/someone"><i className="far fa-eye icon-button-green-style handClickActive"></i></Link> */}
                                    <i className="fas fa-trash-alt icon-button-grey-style handClickActive"></i>
                                </div>
                                <div className="row-message-item">
                                    <p className="date-format-p">{new Date(message.created_at).toISOString().slice(0, 11).replace('T', ' ').trim() === shortDate ? 'Hace poco' : new Date(message.created_at).toISOString().slice(0, 11).replace('T', ' ')}</p>
                                    
                                </div>
                            </div>
                        ))

                    }

                </div>
            </div>
        </>
    )
}
