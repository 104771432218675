import React from 'react';
// import './footer.css';

export const Footer = () => {
    return (
        <div className="footer-container">
            {/* <!-- Footer --> */}
            <footer className="text-center text-lg-start bg-light text-muted">
                {/* <!-- Section: Social media --> */}
                {/* <section
                    className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
                >
                    {/* <!-- Left --> */}
                    {/* <div className="me-5 d-none d-lg-block">
                        <span>Get connected with us on social networks:</span>
                    </div> */}
                    {/* <!-- Left --> */}

                    {/* <!-- Right --> */}
                    {/* <div>
                        <a href="" className="me-4 text-reset">
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="" className="me-4 text-reset">
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a href="" className="me-4 text-reset">
                            <i className="fab fa-google"></i>
                        </a>
                        <a href="" className="me-4 text-reset">
                            <i className="fab fa-instagram"></i>
                        </a>
                        <a href="" className="me-4 text-reset">
                            <i className="fab fa-linkedin"></i>
                        </a>
                        <a href="" className="me-4 text-reset">
                            <i className="fab fa-github"></i>
                        </a>
                    </div> */}
                    {/* <!-- Right --> */}
                {/* </section> */} 
                {/* <!-- Section: Social media --> */}

                {/* <!-- Section: Links  --> */}
                <section className="footer-body1">
                    <div className="container text-center text-md-start mt-5">
                        {/* <!-- Grid row --> */}
                        <div className="row mt-3">
                            {/* <!-- Grid column --> */}
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                {/* <!-- Content --> */}
                                <img className="icon-main-footer" alt="Icon footer" src={process.env.PUBLIC_URL + '/assets/logo2-pie-de-pagina.png'} />
                            </div>
                            {/* <!-- Grid column --> */}

                            {/* <!-- Grid column --> */}
                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                                {/* <!-- Links --> */}
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Contactanos:
                                </h6>
                                <p>
                                    (+57)-1-6151702
                                </p>
                                <p>
                                    ventascol@designdlr.com
                                </p>
                            </div>
                            {/* <!-- Grid column --> */}

                            {/* <!-- Grid column --> */}
                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                {/* <!-- Links --> */}
                                {/* <h6 className="text-uppercase fw-bold mb-4">
                                    Useful links
                                </h6> */}
                                <p>
                                    <a href="#!" className="text-reset">Metodos de pago</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Metodos de envió</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Términos y condiciones</a>
                                </p>
                                {/* <p>
                                    <a href="#!" className="text-reset">Help</a>
                                </p> */}
                            </div>
                            {/* <!-- Grid column --> */}

                            {/* <!-- Grid column --> */}
                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                {/* <!-- Links --> */}
                                {/* <h6 className="text-uppercase fw-bold mb-4">
                                    Contact
                                </h6> */}
                                <p>
                                    <a href="#!" className="text-reset">Reclamos</a>
                                </p>
                                <p>
                                <a href="#!" className="text-reset">Cambios</a>
                                </p>
                                <p>
                                    <a href="#!" className="text-reset">Preguntas frecuentes</a>
                                </p>
                            </div>
                            {/* <!-- Grid column --> */}
                        </div>
                        {/* <!-- Grid row --> */}
                    </div>
                </section>
                {/* <!-- Section: Links  --> */}

                {/* <!-- Copyright --> */}
                <div className="text-center p-4 reders-sociales">
                    © 2021 DesignDLR ™ | Todos los derechos reservados
                    {/* <a className="text-reset fw-bold" href="https://mdbootstrap.com/">MDBootstrap.com</a> */}
                </div>
                {/* <!-- Copyright --> */}
            </footer>
            {/* <!-- Footer --> */}
        </div>
    )
}
