import http from '../axios/http-common';

const authUser = (data) => {
    return http.post('user/auth', data );
}

const registerUser = (data) => {
    return http.post('user', data);
}

const updateUserProfile = (email, data) => {
    return http.put(`user/update/${email}`, data);
}

const updateUserPassword = (email, data) => {
    return http.put(`user/updatepassword/${email}`, data);
}

const exportedObject = {
    authUser, 
    registerUser,
    updateUserProfile,
    updateUserPassword,
};



export default exportedObject;